import React from 'react';

import Cookies from '../components/Cookies';
import Footer from '../components/Footer';
import Header from '../components/Header';
import NotFound from '../components/NotFound';
import SEO from '../components/seo';

import '../assets/styles/core.scss';

const IndexPage = () => (
  <>
    <Header />
    <SEO
      title="Pad - Connecting tenants and landlords"
      description="Top rated app that helps tenants and landlords to rent quicker, easier and more cost-effectively"
    />
    <NotFound />
    <Footer />
    <Cookies />
  </>
);

export default IndexPage;
