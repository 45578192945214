import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

const CookiesComponent = () => {
  const onAccept = ({ acceptedByScrolling }) => {
    Cookies.set('gatsby-gdpr-google-analytics', true);
    Cookies.set('gatsby-gdpr-google-tagmanager', true);
    Cookies.set('gatsby-gdpr-facebook-pixel', true);
  };

  return (
    <CookieConsent
      acceptOnScroll={true}
      disableStyles={true}
      cookieName={'gatsby-gdpr-google-analytics'}
      location="bottom"
      buttonText="Accept"
      buttonClasses="cookies__button"
      containerClasses="cookies__container"
      contentClasses="cookies__content"
      onAccept={onAccept}
    >
      This website uses cookies.
    </CookieConsent>
  );
};

export default CookiesComponent;
