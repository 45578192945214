import React from 'react';

const NotFound = () => {
  return (
    <section className="notfound">
      <div className="container">
        <h2 className="notfound-title">Page not found</h2>
        <p className="notfound-description">Please try again</p>
      </div>
    </section>
  );
};

export default NotFound;
